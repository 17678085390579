import Vue from "vue";
import VueRouter from "vue-router";
// import LandingPage from "../views/Landing/LandingPage.vue";
import HomePage from "../views/Homepage/HomePage.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: LandingPage,
  //   meta: { title: "Speaks to me" },
  // },
  {
    path: "/",
    name: "homepage",
    component: HomePage,
    meta: { title: "Speaks to me" },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About/AboutPage.vue"),
  },
  {
    path: "/product/:slug",
    name: "productDetail",
    component: () => import("../views/ProductDetail/ProductDetailPage.vue"),
  }
];
const DEFAULT_TITLE = "Speaks to me";
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
