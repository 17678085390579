import Vue from "vue";
import axios from 'axios';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toasted from 'vue-toasted';

import interceptorsSetup from './helpers/interceptors';
import VueSkeletonLoader from 'skeleton-loader-vue';
import VideoBackground from 'vue-responsive-video-background-player'

Vue.component('video-background', VideoBackground);

import "./components";
import "./assets/style.scss";
import "./filters";

interceptorsSetup();
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
window.axios = axios;

const customToastOpt = {
  position: 'top-center',
  singleton: true,
  duration: 3333,
  action: {
    text: '×',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

Vue.use(Toasted, customToastOpt);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
