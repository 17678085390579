import Axios from 'axios';

export default function setup() {
  // Add request interceptors
  Axios.interceptors.request.use(
    (config) => {
      config.headers['Source'] = window.innerWidth > 1100 ? 'speakstome-web-desktop' : 'speakstome-web-mobile';
      config.withCredentials = true;
      // config.headers['Authorization'] = document.cookie;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add response interceptors
  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
