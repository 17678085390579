export const currency = (value) => {
    if (isNaN(value) || !value) {
        value = '0';
    }
  
    let minus = '';
    if (value.toString().indexOf('-') > -1) {
        value = value.toString().replace('-', '');
        minus = '- ';
    }
    value = parseInt(value);
  
    const res = value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
    return res === '0' ? '' : `${minus} Rp ${res}`;
};