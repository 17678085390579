<template>
    <div>
        <top-header @onClickNavigation="handleClickNavigation"/>
        <homepage-big-banner />
        <observer @on-change="productGridHeaderViewed" >
            <div ref="aboutRef" class="home-section-1">
                <transition name="expand-fade" @after-enter="isPunchlineLabelShow = true">
                    <img v-show="isPunchlineViewed" src="@/assets/img/homepage-asset-1.svg" alt="Discover the scent that speaks to you"/>
                </transition>
                <transition name="slide-in-right">
                    <p v-show="isPunchlineLabelShow">
                        Every note embarks you <br/>
                        on a deeply personal discovery, <br/>
                        turning timeless treasures into scents <span>to you</span>.
                    </p>
                </transition>
            </div>
        </observer>
        <div ref="discoverRef" class="home-section-2">
            <header>
                <h4>soulmade</h4>
                <p>Each scents transform you back to the moment that define your soul.</p>
            </header>
            <homepage-product-grid :data="productGridData" :is-loading="isProductGridLoading" />
        </div>
        <homepage-instagram-feed v-if="false" />
        <bottom-footer :border-top="false" @onClickNavigation="handleClickNavigation"/>
    </div>
</template>

<script>
import Observer from 'vue-intersection-observer';

export default {
  components: {
    TopHeader: () => import('@/components/header/topHeader.vue'),
    BottomFooter: () => import("@/components/footer/bottomFooter"),
    HomepageProductGrid: () => import("./Components/HomepageProductGrid.vue"),
    HomepageInstagramFeed: () => import("./Components/HomepageInstagramFeed.vue"),
    HomepageBigBanner: () => import("./Components/HomepageBigBanner.vue"),
    Observer
  },
  data() {
    return {
        isPunchlineViewed: false,
        isPunchlineLabelShow: false,
        firstLoad: true
    };
  },
  computed: {
    isProductGridLoading () {
        return this.$store.state.$_Products.isLoading;
    },
    productGridData () {
        return this.$store.state.$_Products.products ? this.$store.state.$_Products.products.map(
            item => {
                let defaultCombinations = item.combinations.find(comb => comb.is_default);
                if (!Boolean(defaultCombinations)) {
                    defaultCombinations = item.combinations[0];
                }
                const defaultImage = defaultCombinations.images.find(img => img.is_cover);
                const prices = item.combinations.map(comb => {
                    return {
                        label: comb.attributes.size.name,
                        value: comb.price_after_discount
                    }
                });
                const allImages = item.combinations.map(comb => 
                    comb.images.map(img => img.url).flat()
                ).flat();
                return { 
                    name: item.name,
                    description: item.speakstome.tagline,
                    price: prices,
                    image: defaultImage.url,
                    allImages: allImages, 
                    clickUrl: item.url_sociolla,
                    slug: item.slug
                }
            }
        ) : [];
    }
  },
  watch: {
    $route(from, to) {
        this.scrollToAnchor(to.hash);
    }
  },
  mounted() {
    this.fetchProductData();
    setTimeout(() => {
        this.scrollToAnchor(this.$route.hash);
    }, 1000);
  },
  methods: {
    handleClickNavigation(param) {
        this.scrollToAnchor(param);
    },
    scrollToAnchor(hashRoute) {
        if (!hashRoute) {
            return;
        }
        if (hashRoute.includes('about')) {
            this.$refs.aboutRef.scrollIntoView({ behavior: 'smooth'});
        }
        if (hashRoute.includes('discovery')) {
            this.$refs.discoverRef.scrollIntoView({ behavior: 'smooth'});
        }
    },
    productGridHeaderViewed(entry, unobserve) {
        if (entry.isIntersecting) {
            if (window.innerWidth <= 1024 || !this.firstLoad || this.$route.hash?.length > 0) {
                this.isPunchlineViewed = true;
                unobserve();
            } else {
                this.firstLoad = false;
            }
        }
    },
    fetchProductData() {
        if (this.productGridData.length === 0) {
            this.$store.dispatch('$_Products/fetchProducts', this);
        }
    }
  }
};
</script>

<style lang="scss" scoped>
.home-section-1 {
    display: grid;
    font-family: Poppins;
    letter-spacing: 0em;
    text-align: left;
    align-items: center;
    span {
        font-style: italic;
        font-weight: 700;
    }
}
.home-section-2 {
    header {
        h4 {
            font-family: Poppins;
            font-weight: 700;
            letter-spacing: 0.02em;
            margin: 0;
            padding: 0;
        }
        p {
            font-family: Poppins;
            font-weight: 500;
            letter-spacing: 0em;
            margin: 0;
            padding: 0;
        }
    }
}
@media (max-width: 768px) {
    .home-section-1 {
        grid-template-columns: 1fr;
        padding: 20px;
        font-size: 21px;
        line-height: 21px;
        scroll-margin-top: 87px;
        img {
            width: 100%;
        }
        height: 254px;
        align-items:start;
    }
    .home-section-2 {
        scroll-margin-top: 87px;
        header {
            padding: 0px 20px;
            font-family: Poppins;
            font-size: 30px;
            line-height: 38px;
            text-align: left;
        }
        p {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}
@media (min-width: 769px) {
    .home-section-1 {
        grid-template-columns: 1fr 1fr;
        padding: 20px 0px 20px 69px;
        font-size: 26px;
        line-height: 39px;
        height: 248px;
        scroll-margin-top: 92px;
        img {
            width: calc(85%);
        }
    }
    .home-section-2 {
        scroll-margin-top: 92px;
        header {
            font-size: 50px;
            line-height: 80px;
            text-align: center;
        }
        p {
            font-size: 18px;
            line-height: 27px;
            text-align: center;
        }
    }
}
.expand-fade-enter-active, .expand-fade-leave-active {
  transition: opacity 1s;
}
.expand-fade-enter, .expand-fade-leave-to {
  opacity: 0;
}
.slide-in-right-enter-active, .slide-in-right-leave-active {
  transition: opacity 1s, transform 1s;
}
.slide-in-right-enter, .slide-in-right-leave-to {
  transform: translateX(5%);
  opacity: 0;
}
</style>