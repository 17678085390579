import Vue from "vue";
import Vuex from "vuex";

import $_Screen from "./$_Screen.store";
import $_Products from "./$_Products.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    $_Screen,
    $_Products
  },
});
