const Config = require('../../config/default.env').default;

export default {
    namespaced: true,
    state: {
        products: [],
        productDetail: {},
        selectedCombination: {},
        isLoading: false
    },
    mutations: {
        SET_PRODUCTS(state, data) {
            state.products = data;
        },
        SET_PRODUCT_DETAIL(state, data) {
            state.productDetail = data;
        },
        SET_LOADING(state, data) {
            state.isLoading = data;
        },
        SET_SELECTED_COMBINATION(state, data) {
            state.selectedCombination = data;
        }
    },
    actions: {
        setSelectedCombination({ commit }, data) {
            data.images.sort((a, b) => {
                if (a.is_cover) {
                    return -1;
                } else if (b.is_cover) {
                    return 1;
                } else {
                    return 0;
                }
            })
            commit("SET_SELECTED_COMBINATION", data);
        },
        setProductDetail({ commit, state }, slug) {
            const productDetail =  state.products.find(product => product.slug === slug);
            const defaultCombination = productDetail.combinations.find(comb => comb.is_default);
            commit("SET_PRODUCT_DETAIL", productDetail);
            commit("SET_SELECTED_COMBINATION", defaultCombination);
        },
        fetchProducts({ commit }, context) {
            if (this.isLoading) {
                return;
            }
            commit("SET_LOADING", true);
            const filter = encodeURIComponent(JSON.stringify({ "is_active_in_speakstome": true }));
            const url = `${Config.MS_CATALOG_API_URL}/v3/products?filter=${filter}&limit=6`;
            return context.$axios.get(url).then((res) => {
                if (res?.data?.data && res?.data?.data?.length > 0) {
                    const products = res.data.data.map(prod => {
                        const activeInSpeakstomeCombination = prod.combinations.filter(comb => comb.is_active_in_speakstome);
                        prod.combinations = activeInSpeakstomeCombination;
                        return prod;
                    });
                    commit("SET_PRODUCTS", products.filter(product => Boolean(product['speakstome']) && product.combinations.length > 0));
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                commit("SET_LOADING", false);
            })
        }
    },
    getters: {},
}