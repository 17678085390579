export default {
  namespaced: true,
  state: {
    width: "",
    height: "",
    isDesktop: "",
  },
  mutations: {
    setScreenWidth(state, windowWidth) {
      state.width = windowWidth;
    },
    setScreenHeight(state, windowHeight) {
      state.height = windowHeight;
    },
    setInfoScreen(state, windowWidth) {
      if (windowWidth > 991) {
        state.isDesktop = true;
      } else {
        state.isDesktop = false;
      }
    },
  },
  actions: {
    setScreenSize({ commit }, dataScreen) {
      commit("setScreenWidth", dataScreen.width);
      commit("setScreenHeight", dataScreen.height);
      commit("setInfoScreen", dataScreen.width);
    },
  },
  getters: {},
};
