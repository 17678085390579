<template src="./modal.html"></template>

<script>
export default {
  name: "my-modal",
  props: {
    contentStyle: {
      type: [String, Object],
      default: "",
      required: false,
    },
  },
  data() {
    return {
      showModal: true,
      positionScroll: 0,
    };
  },
  computed: {
    is_desktop() {
      return this.$store.state.$_Screen.isDesktop;
    },
  },
  updated() {
    this.fixedPositionModal();
  },
  created() {
    this.fixedPositionModal();
  },
  mounted() {
    this.fixedPositionModal();

    this.showModal = true;
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.closeModal();
      }
    });
  },
  beforeDestroy() {
    this.handleScroll();
  },
  methods: {
    fixedPositionModal() {
      if (!document.documentElement.classList.contains("noscrollv2")) {
        document.documentElement.classList.add("noscrollv2");
        try {
          if (!this.positionScroll && window.scrollY) {
            this.positionScroll = window.scrollY;
            document.documentElement.style.marginTop = `-${this.positionScroll}px`;
            document.documentElement.style.overflow = "hidden";
            document.documentElement.style.position = "fixed";
            document.documentElement.style.top = 0;
            document.documentElement.style.bottom = 0;
            document.documentElement.style.left = 0;
            document.documentElement.style.right = 0;
            document.body.style.position = "relative";
            window.scrollTo(0, this.positionScroll);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    handleScroll() {
      if (document.documentElement.classList.contains("noscroll")) {
        document.documentElement.classList.remove("noscroll");
      }
      if (document.documentElement.classList.contains("noscrollv2")) {
        document.documentElement.classList.remove("noscrollv2");
        try {
          document.documentElement.removeAttribute("style");
          document.body.removeAttribute("style");
          window.scrollTo(0, this.positionScroll);
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "modal";
</style>
