<template>
  <div id="app">
    <template v-if="isAppReady">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </template>
  </div>
</template>
<script>
const Config = require('../config/default.env').default;

export default {
  data() {
    return {
      isAppReady: false
    }
  },
  // watch:{
  //   isAppReady(val) {
  //     if (val) {
  //       this.$store.dispatch('$_Products/fetchProducts', this);
  //     }
  //   }
  // },
  async created() {
    if (document.cookie.indexOf('sso_token') < 0) {
      this.$axios
        .get(Config.MS_SSO_BROKER_API_URL)
        .then((res) => {
          try {
            if (res.request.responseURL) {
              const url = new URLSearchParams(res.request.responseURL);
              document.cookie = 'sso_token=' + url.get('token') + '; domain=.speakstomefragrance.com;';
            }
          } catch (err) {
            // this.$toasted.global.error(err);
          }
          this.makeAppReady();
        })
        .catch((err) => {
          // this.$toasted.global.error(err);
        });
    } else {
      this.makeAppReady();
    }
  },
  methods: {
    makeAppReady() {
      if (document.cookie.indexOf('sso_token') > -1) {
        this.isAppReady = true;
      } else {
        let countInterval = 0;
        const int = setInterval(() => {
          countInterval = countInterval + 100;
          if (document.cookie.indexOf('sso_token') > -1 || countInterval == 4000) {
            clearInterval(int);
            this.isAppReady = true;
          }
        }, 100);
      }
    },
  },
}
</script>
<style>
  @import url("https://fonts.googleapis.com/css?family=Poppins");

  html,
  body {
    margin: 0;
  }
  #app {
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  html {
    font-family: "Poppins", sans-serif;
  }

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
